import { useEffect, useRef, useState } from 'react';
import { Animated, Alert } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { StackActions, useNavigation } from '@react-navigation/native';
import { CANCEL_ERROR } from 'apisauce';
import {
  filter as lodashFilter,
  find as lodashFind,
  get as lodashGet,
  isEmpty as lodashIsEmpty,
  isUndefined as lodashIsUndefined,
  pick as lodashPick,
  sortBy as lodashSortBy,
} from 'lodash';

import modals from '../../../Components/Sheets/modals';
import constants from '../../../Config/constants';
import messages from '../../../Config/messages';
import AnalyticsHelper from '../../../Helper/Analytics';
import MenuItemHelper from '../../../Helper/MenuItem';
import Sentry from '../../../Helper/Sentry';
import useAlert from '../../../Hooks/useAlert';
import useCancellableRequest from '../../../Hooks/useCancellableRequest';
import useStorePersist from '../../../Hooks/useStorePersist';
import routeList from '../../../Routes/list';
import { updateCheckoutDetails } from '../../../RTK/checkout';
import { checkoutOrderTypeSelector } from '../../../RTK/checkout/selectors';
import { checkout } from '../../../RTK/defaultValues';
import { reset } from '../../../RTK/mealPlan';
import {
  setShopData,
  setShopRawData,
  removeShopData,
  setShopOrderForLater,
} from '../../../RTK/shop';
import Service from '../../../Service';
import storeApi from '../../../Service/api/store';

const { ORDER_TYPE_API_KEYS, ORDER_TYPES, ORDER_TYPE_DATA } = constants;
const defaultState = { scroll: new Animated.Value(0) };

// this is for functional component
function useController(props) {
  const storeId = lodashGet(props, 'route.params.id');
  const orderDateParams = lodashGet(props, 'route.params.order_date');
  const orderTimeParams = lodashGet(props, 'route.params.order_time');
  const isOrderForLater = lodashGet(props, 'route.params.orderForLater');
  const storeParamsData = lodashPick(props?.route?.params, [
    'distance',
    'duration',
  ]);

  const alert = useAlert();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const elementHeightRef = useRef({ header: 0, categories: 0 });
  const { cancelPendingRequest, createRequest } = useCancellableRequest();
  const { getSavedStore, updateCache, updateSavedStore } = useStorePersist({
    ...storeParamsData,
    orderDateParams,
    orderTimeParams,
  });
  const orderTypeHasBeenSet = useRef();
  const isMenuLoaded = useRef();
  const isMounted = useRef();
  const homeTagsFilter = useSelector((state) => state.filter.home.tags);
  const mealPlanData = useSelector((state) => state.mealPlan?.data);
  const searchTagsFilter = useSelector((state) => state.filter.search.tags);
  const selectedOrderType = useSelector((state) =>
    checkoutOrderTypeSelector(state, storeId)
  );
  const whenFilter = useSelector((state) => state.filter.home.when);
  const { loading: shopLoading, data: shopData } = useSelector(
    (state) => state.shop
  );

  const [scroll] = useState(defaultState.scroll);
  const [isGettingMenu, setGettingMenu] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [menuError, setMenuError] = useState('');
  const [apiData, setApiData] = useState(); // used for changing menu back and fort to meal plan and not meal plan menu

  const scrollOffset =
    elementHeightRef.current.header + elementHeightRef.current.categories;

  // this hooks responsible for filtering the store menu base (meal plan or not meal plan)
  useEffect(() => {
    // _toggleMealPlanMenu should call only after setting the shop data and user changed the order type
    // This should not be called onload since shopData is nil onload (if this called onload, store page get rerender twice it should only render once)
    if (!lodashIsEmpty(shopData)) {
      _toggleMealPlanMenu();
    }
  }, [selectedOrderType]);

  // should only execute once after shopData has been set
  // effect for setting the default order type in store page
  useEffect(() => {
    if (!lodashIsEmpty(shopData) && !orderTypeHasBeenSet.current) {
      // check for previous screen only for search
      // because aside from search, other screen to access store page must use the homepage tag filter
      const routes = navigation.getState().routes; // get routes
      const prevRoutes = routes[routes.length - 2]; // get previous routes
      const isPreviousRouteSearch =
        prevRoutes?.name === routeList.SEARCH ? true : false; // is previous page is search ?
      const homeOrderTypeFilter = lodashFind(homeTagsFilter, {
        paramName: 'order_type',
      }); // get tag filter on homepage
      const searchOrderTypeFilter = lodashFind(searchTagsFilter, {
        paramName: 'order_type',
      }); // get tag filter on search page
      const tagFilter = isPreviousRouteSearch
        ? searchOrderTypeFilter
        : homeOrderTypeFilter; // if previous route is search: use the search tag filter, else home tag filter
      if (!lodashIsEmpty(tagFilter)) {
        _changeOrderTypeToSelectedTagFilter(tagFilter);
      }
      orderTypeHasBeenSet.current = true; // for flag, for this not to be called every change of menu (meal plan to non meal plan vice versa)
    }
  }, [shopData]);

  // this effect is for handling toggle of menu onload if user change menu to meal pla or not meal plan while menu is still fetching
  // this effect if condition should trigger only once
  useEffect(() => {
    // if menu is loaded & menu_list is not empty
    if (isMenuLoaded.current && !lodashIsEmpty(shopData?.menu_list)) {
      _toggleMealPlanMenu(); // toggle menu to meal plan or not meal plan
      isMenuLoaded.current = false; // update flag
    }
  }, [isMenuLoaded.current]);

  // this effect only handle data clean up on unmount
  useEffect(() => {
    // added delay 100ms if from order for later section to propagate the changes on navigation route params
    setTimeout(_getStoreData, isOrderForLater ? 100 : 0);
    return () => {
      // clean up when store page is closed
      dispatch(reset()); // clear meal plan step
      dispatch(removeShopData()); // clear store data
    };
  }, [storeId]); // listen for changes of storeId on url parameter

  useEffect(() => {
    if (isMounted.current) {
      _getStoreData();
    }
  }, [whenFilter?.date, whenFilter?.time]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const _changeOrderTypeToSelectedTagFilter = (orderTypeFilter) => {
    const orderType = orderTypeFilter.values[0].value;
    const type = lodashFind(constants.ORDER_TYPE_DATA, { value: orderType });
    dispatch(
      updateCheckoutDetails({
        store_id: shopData.id,
        keyToUpdate: checkout.keys.ORDER_TYPE,
        keyValue: type,
      })
    );
  };

  const _isMealPlan = (storeData) => {
    if (selectedOrderType) {
      return selectedOrderType === ORDER_TYPES.MEAL_PLAN;
    } else {
      const orderTypeApiKeys = lodashPick(storeData, ORDER_TYPE_API_KEYS); // get order type api keys on store data
      // prettier-ignore
      const availableOrderTypes = lodashFilter(ORDER_TYPE_DATA, (d) => orderTypeApiKeys[d.apiDataKey]) // filter order types to available order type of the store
      const defaultOrderType = availableOrderTypes[0]?.value; // default order type of the store
      return defaultOrderType === ORDER_TYPES.MEAL_PLAN;
    }
  };

  // for changing the menu to meal plan and not meal plan
  const _toggleMealPlanMenu = () => {
    const isMealPlanAlready = shopData?.featured_items[0]?.is_meal_plan;
    const isMealPlan = _isMealPlan();
    // below condition trigger only once for meal plan and not meal plan menu
    // meaning, if already not meal plan menu then change order type to not meal plan again, below code not gonna get executed
    if (isMealPlan && !isMealPlanAlready) {
      _setShopMenu(apiData);
    } else if (!isMealPlan && isMealPlanAlready) {
      _setShopMenu(apiData);
    }
  };

  // for resetting state value
  const _resetState = async (isRefreshing) => {
    scroll.setValue(0); // reset scroll animation value
    // reset states
    setGettingMenu(true);
    setApiData();
    if (!lodashIsEmpty(shopData) && !isRefreshing) {
      // if shopData is not empty and not refreshing, it means store is already loaded and it was changed
      // due to change of storeId probably user click the store name in cart page while store page still loaded
      cancelPendingRequest(); // cancel all pending request
      dispatch(reset()); // reset meal plan
      return new Promise((resolve) => setTimeout(resolve, 100)); // add little delay to propagate the changes on redux to other component
    }
  };

  // for getting store data
  const _getStoreData = async (isRefreshing) => {
    await _resetState(isRefreshing); // reset state to default
    dispatch(removeShopData()); // clear store/shop data

    if (storeId) {
      let storeDataOk = true;
      let storeData = getSavedStore(storeId);
      let storeProblem = '';
      if (lodashIsEmpty(storeData)) {
        const { ok, data, problem } = await createRequest(
          storeApi.getDetails,
          storeId
        );
        storeData = { ...data, ...storeParamsData };
        storeDataOk = ok;
        storeProblem = problem;
      } else {
        storeData = { ...storeData, ...storeParamsData };
        updateSavedStore(storeId, storeParamsData);
      }
      updateCache(storeId);
      // stop the code from going if request is cancelled
      if (storeProblem === CANCEL_ERROR) {
        return;
      }
      if (storeDataOk) {
        storeData.categories = []; // initial value for _getStoreMenu
        storeData.menu = []; // add initial value for _getStoreMenu
        setApiData(storeData); // save for filter of meal plan and not meal plan, must be save before calling _setShopMenu
        const isOk = await _setShopMenu(storeData); // for showing initial UI of store page
        if (isOk) {
          _getStoreMenu(storeData); // get store menu
        }
      } else {
        navigation.canGoBack() && navigation.goBack();
        const msg = storeData?.message || messages.COMMON_ERROR_MESSAGE;
        Sentry.reportError('Error getting store details', storeData);
        Alert.alert(
          messages.COMMON_ERROR_TITLE,
          Service.handleErrorMessage(msg)
        );
      }
    } else {
      navigation.canGoBack() && navigation.goBack();
      Alert.alert('Please try again', 'Store ID is invalid.');
    }
  };

  // for getting store menu
  const _getStoreMenu = async (storeInfo) => {
    setGettingMenu(true);
    const { ok, data, problem } = await createRequest(
      storeApi.getMenu,
      storeId,
      whenFilter?.date || orderDateParams,
      whenFilter?.time || orderTimeParams
    );
    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }
    if (ok) {
      // filter category to show only visible category
      const visibleCategories = lodashFilter(data.categories, {
        visible: true,
      });
      // sort category
      const sortedVisibleCategories = lodashSortBy(visibleCategories, [
        'category_position',
      ]);
      const updatedMenu = {
        ...storeInfo,
        categories: sortedVisibleCategories,
        menu: data.menu,
      };
      setApiData(updatedMenu); // save for filter of meal plan and not meal plan, must be save before calling _setShopMenu
      // save store api raw data for storing to the store cache when user add to cart
      dispatch(setShopRawData(updatedMenu));
      dispatch(setShopOrderForLater(isOrderForLater));
      await _setShopMenu(updatedMenu);
      isMenuLoaded.current = true;
    } else {
      const msg = data?.message || messages.COMMON_ERROR_MESSAGE;
      Sentry.reportError('Error getting store menu', data);
      setMenuError(msg); // show error message on the UI by updating its state
    }
    setGettingMenu(false); // mark getting store menu as done
    setRefreshing(false);
  };

  const _setShopMenu = async (data) => {
    const { menu, ...otherStoreData } = data;
    const isMealPlan = _isMealPlan(data);
    const constructedMenuList = [];
    // group the item to their category
    for (let i = 0; i < data?.categories?.length; i++) {
      const category = data.categories[i];
      if (category.stock === -1 || category.stock > 0) {
        // process only if category has stock
        let copyMenu = JSON.parse(JSON.stringify(menu)); // this to prevent warning, Attempted to assign to readonly property
        let categoryItems = lodashFilter(
          copyMenu,
          (e) => e.category === category.category_name
        );
        const filteredCategoryItems = lodashFilter(categoryItems, (ci) => {
          ci.item.is_exclusive = category.is_exclusive; // add is_exclusive key to item
          ci.item.stock = ci.stock;
          ci.item.category_stock = category.stock;
          return MenuItemHelper.getAvailableItem(ci, isMealPlan);
        });
        // only push category if has category item
        if (!lodashIsEmpty(filteredCategoryItems)) {
          constructedMenuList.push({
            isExclusive: category.is_exclusive,
            title: category.category_name,
            subTitle: category.is_exclusive ? messages.MEAL_PLAN_EXCLUSIVE : '',
            categoryStock: MenuItemHelper.getCategoryOrItemStock(category), // for display purpose only of category stock
            data: lodashSortBy(filteredCategoryItems, ['order_position']), // sort item
          });
        }
      }
    }
    // #region modify constructedMenuList shape to only have flat item object instead of item.item
    // before { ...category_info, data: [{ item: { ...item_info }, ...other_info }] }
    constructedMenuList?.map?.((ml) => {
      ml.data = ml.data.map(({ item, ...rest }) => ({ ...item, ...rest }));
      return ml;
    });
    // after { ...category_info, data: [{ ...item_info, ...other_info }] }
    // #endregion
    if (isOrderForLater) {
      // if orderForLater is pass on params, store is access on order for later section
      // store the earliest time can pre order on the store.
      // order_for_later is to be access on useCart hooks on _addOrUpdateCart function
      // prettier-ignore
      const orderDaysInAdvance = otherStoreData.is_accepting_in_advanced_orders ? otherStoreData.days_accepting_in_advanced_orders || 0 : 0
      const dateOptions = Service.generateDateOptions({
        storeHours: otherStoreData.store_hours,
        offDates: otherStoreData.off_dates,
        daysInAdvance: orderDaysInAdvance,
        prepTime: otherStoreData.pre_order_to_order_queue_timer,
        dateAndTime: whenFilter?.value, // to generate dateOptions base on whenFilter
      });
      // set order_for_later date to use for payload of add/update cart date and time
      otherStoreData.order_for_later = dateOptions[0];
      if (lodashIsEmpty(otherStoreData.order_for_later)) {
        // if otherStoreData.order_for_later is empty it means store is not accepting advance order
        alert(
          otherStoreData?.name,
          'Sorry this store is not accepting advance order.'
        );
        if (constants.isWeb) {
          navigation.dispatch(
            StackActions.replace(routeList.HOME, {
              screen: 'MainMenu',
              params: { screen: routeList.ROOT_TAB },
            })
          );
        } else if (navigation.canGoBack()) {
          navigation.goBack();
        }
        return false;
      }
    }
    // save store information for store page to prevent props drilling
    dispatch(
      setShopData({
        ...otherStoreData,
        featured_items: constructedMenuList[0]?.data || [], // use first menu list data as featured items
        menu_list: constructedMenuList,
      })
    );
    return true;
  };

  // when user click the floating text below store page (go to cart/basket page)
  const _goToCartPage = (storeId) => {
    navigation.navigate(routeList.BASKET, { activeTab: 0, storeId });
  };

  // when user click menu item
  const _onItemPressed = (item, context) => () => {
    AnalyticsHelper.itemSelection({
      ...item,
      fromSection: 'From store page menu',
    });
    modals.show(modals.PRODUCT, item, context);
  };

  const _onRefresh = () => {
    if (!shopLoading && !isGettingMenu) {
      setRefreshing(true);
      _getStoreData(true);
    }
  };

  const _showMealPlanList = () => modals.show(modals.MEAL_PLAN_LIST);

  // for showing the modal when user click the left menu of category (when user able to see the sticky category above)
  const _showMenuList = (reference) => async () => {
    const list = shopData.menu_list;
    const selectedIndex = await modals.show(modals.FULL_MENU_LIST, {
      list,
      value: reference.current?._reactInternals?.memoizedState?.currentIndex,
    });
    if (!lodashIsUndefined(selectedIndex)) {
      reference.current?.scrollToLocation?.(selectedIndex);
    }
  };

  return {
    elementHeightRef,
    state: {
      isGettingMenu,
      menuError,
      refreshing,
      scroll,
    },
    isMealPlan: _isMealPlan(),
    mealPlanItems: mealPlanData?.items,
    scrollOffset,
    goToCartPage: _goToCartPage,
    onItemPressed: _onItemPressed,
    onRefresh: _onRefresh,
    showMealPlanList: _showMealPlanList,
    showMenuList: _showMenuList,
  };
}

export default useController;
